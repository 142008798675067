import React from 'react';
import { Grid } from 'semantic-ui-react';

import Layout from '../components/layout';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';

import SEO from '../components/seo';
import IntroContent from '../components/Page/IntroContent/IntroContent';
import Atlas from '../components/Atlas/Atlas';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Grid.Row
      className="flush"
      style={{ height: '45px', display: 'flex', alignItems: 'center', margin: '0rem 1rem' }}
    >
      <Breadcrumb pathname="/" />
    </Grid.Row>
    <Grid.Row className="flush">
      <Grid.Column className="sidebar" computer={6} mobile={16}>
        <IntroContent />
      </Grid.Column>
      <Grid.Column width={10} className="map" only="computer">
        <Atlas intro />
      </Grid.Column>
    </Grid.Row>
  </Layout>
);

export default IndexPage;
