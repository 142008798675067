import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Header } from 'semantic-ui-react';

export default function PageBlock(props) {
  return (
    <Segment padded>
      {props.title && <Header as="h3">{props.title}</Header>}
      {props.children}
    </Segment>
  );
}

PageBlock.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};
