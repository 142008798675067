import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon, Dropdown } from 'semantic-ui-react';

import './AppDropdown.css';

export default function AppDropdown() {
  const {
    allAirtable: { apps },
  } = useStaticQuery(graphql`
    {
      allAirtable(filter: { table: { eq: "Cities" } }) {
        apps: nodes {
          data {
            Name
            App_Link
            Play_Link
          }
        }
      }
    }
  `);
  return (
    <Dropdown
      icon="mobile alternate"
      text="Download our apps to explore HistoryCity"
      labeled
      button
      className="icon app"
    >
      <Dropdown.Menu>
        {apps.map(a => (
          <>
            <Dropdown.Item key={a.data.Name} className="storeLink">
              {a.data.Name}
              <a href={a.data.Play_Link} target="_blank" rel="noreferrer">
                <Icon name="google play" link size="medium" circular inverted />
              </a>
              <a href={a.data.App_Link} target="_blank" rel="noreferrer">
                <Icon name="app store" link size="medium" circular inverted />
              </a>
            </Dropdown.Item>
          </>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
