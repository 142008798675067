import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Card, Image } from 'semantic-ui-react';
import parse from 'html-react-parser';

import PageBlock from '../PageBlock/PageBlock';
import ImageCard from '../ImageCard/ImageCard';
import AppDropdown from '../AppDropdown/AppDropdown';

import './IntroContent.css';

export default function IntroContent() {
  return (
    <StaticQuery
      query={graphql`
        query {
          about: wpPage(slug: { eq: "about" }) {
            content
          }
          home: wpPage(slug: { eq: "home" }) {
            content
          }
          bellingradt: file(relativePath: { eq: "team/bellingradt.jpg" }) {
            ...teamImage
          }
          bolufer: file(relativePath: { eq: "team/bolufer.jpg" }) {
            ...teamImage
          }
          corbellini: file(relativePath: { eq: "team/corbellini.png" }) {
            ...teamImage
          }
          gomis: file(relativePath: { eq: "team/gomis.jpg" }) {
            ...teamImage
          }
          nevola: file(relativePath: { eq: "team/nevola.png" }) {
            ...teamImage
          }
          rospocher: file(relativePath: { eq: "team/rospocher.jpg" }) {
            ...teamImage
          }
          exeter: file(relativePath: { eq: "logos/exeter.jpg" }) {
            ...logoImage
          }
          fau: file(relativePath: { eq: "logos/fau.jpg" }) {
            ...logoImage
          }
          hera: file(relativePath: { eq: "logos/hera.png" }) {
            ...logoImage
          }
          isig: file(relativePath: { eq: "logos/isig.jpg" }) {
            ...logoImage
          }
          rugr: file(relativePath: { eq: "logos/rugr.jpg" }) {
            ...logoImage
          }
          valencia: file(relativePath: { eq: "logos/valencia.jpg" }) {
            ...logoImage
          }
        }
      `}
      render={data => (
        <div className="introContent">
          <h3 style={{ margin: '35px 0px 20px 0px' }}>Project Overview</h3>
          <PageBlock>
            <AppDropdown />
            {parse(data.home.content)}
          </PageBlock>

          <h3 style={{ margin: '35px 0px 20px 0px' }}>Our videos</h3>
          <PageBlock>
            <div className="videoContainer">
              <iframe
                title="video1"
                width="100%"
                src="https://www.youtube.com/embed/S0kCWzcOp1E"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </PageBlock>

          <h3 style={{ margin: '35px 0px 20px 0px' }}>Meet the team</h3>
          <PageBlock>
            <Card.Group itemsPerRow={3}>
              <ImageCard
                image={data.nevola.childImageSharp.fluid.src}
                name="Prof. Fabrizio Nevola, Project Leader"
                description="University of Exeter, United Kingdom"
                link="http://humanities.exeter.ac.uk/arthistory/staff/nevola/research"
              />
              <ImageCard
                image={data.bellingradt.childImageSharp.fluid.src}
                name="Prof. Dr. Daniel Bellingradt, Project Investigator"
                description="Friedrich-Alexander-Universität Erlangen-Nürnberg (FAU), Germany"
                link="https://www.buchwiss.uni-erlangen.de/institut/teammitglieder/daniel-bellingradt/"
              />
              <ImageCard
                image={data.bolufer.childImageSharp.fluid.src}
                name="Prof Mónica Bolufer Peruga, Project Investigator"
                description="Universitat de València , Spain"
                link="https://www.uv.es/uvweb/universidad/es/ficha-persona-1285950309813.html?p2=mbolufer"
              />
              <ImageCard
                image={data.corbellini.childImageSharp.fluid.src}
                name="Prof. Dr. Sabrina Corbellini, Project Investigator"
                description="University of Groningen, Netherlands"
                link="https://www.rug.nl/staff/s.corbellini/"
              />
              <ImageCard
                image={data.rospocher.childImageSharp.fluid.src}
                name="Dr. Massimo Rospocher, Project Investigator"
                description="Fondazione Bruno Kessler, Italian-German Historical Institute, Italy"
                link="https://isig.fbk.eu/en/people/detail/massimo-rospocher/"
              />
              <ImageCard
                image={data.gomis.childImageSharp.fluid.src}
                name="Dr. Juan Gomis Coloma, Senior Researcher"
                description="Universidad Católica de Valencia, Spain"
              />
            </Card.Group>
          </PageBlock>
          <h3 style={{ margin: '35px 0px 20px 0px' }}>Institutions</h3>

          <PageBlock>
            <Image.Group>
              <Image className="logo" src={data.hera.childImageSharp.fixed.src} />
              <Image className="logo" src={data.exeter.childImageSharp.fixed.src} />
              <Image className="logo" src={data.fau.childImageSharp.fixed.src} />
              <Image className="logo" src={data.isig.childImageSharp.fixed.src} />
              <Image className="logo" src={data.rugr.childImageSharp.fixed.src} />
              <Image className="logo" src={data.valencia.childImageSharp.fixed.src} />
            </Image.Group>
          </PageBlock>

          <h3 style={{ margin: '35px 0px 20px 0px' }}>Follow us on Twitter</h3>
          <PageBlock>
            <a
              className="twitter-timeline"
              data-dnt="true"
              href="https://twitter.com/HistoryCitysApps?ref_src=twsrc%5Etfw"
            >
              Tweets by HistoryCitysApps
            </a>
          </PageBlock>
        </div>
      )}
    />
  );
}

export const query = graphql`
  fragment teamImage on File {
    childImageSharp {
      fluid(maxWidth: 190, maxHeight: 220) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  fragment logoImage on File {
    childImageSharp {
      fixed(width: 140) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;
