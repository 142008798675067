import React from 'react';
import PropTypes from 'prop-types';
import { Image, Card } from 'semantic-ui-react';

export default function ImageCard(props) {
  return (
    <Card style={{ minWidth: '9rem' }} fluid>
      <Image src={props.image} wrapped />
      <Card.Content>
        <Card.Header
          as={props.link ? 'a' : 'span'}
          href={props.link}
          target="_blank"
          style={{ fontSize: '1rem' }}
        >
          {props.name}
        </Card.Header>
        <Card.Description>{props.description}</Card.Description>
      </Card.Content>
    </Card>
  );
}

ImageCard.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  description: PropTypes.string,
};
